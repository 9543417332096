import { eachComponent } from '@platform/formio/utils/formUtils';
import { AdditionalInfoFileDTO } from '../types/additionalInfo';

/**
 * Функция извлекает из данных формы "Property Name" элементов
 * @param components Массив компонент формы (обычно приходит с бэка как formInfo.form.components)
 * @param typesToMatch Массив типов компонент (например, ['categoryFile', 'textarea']), для которых необходимо извлечь "Property Name"
 * @param includeAll Если true, то происходит итераиция и по layout-компонентам
 * ("Property Name" в компоненте хранится как component.key)
 */
export const getComponentsKeysByType = (
    components: Array<Record<string, any>>,
    typesToMatch: string[],
    includeAll = false,
): Array<string> => {
    const accumulator: Array<string> = [];
    eachComponent(
        components,
        (component) => {
            if (component.key && component.type) {
                if (typesToMatch.includes(component.type)) {
                    accumulator.push(component.key);
                }
            }
        },
        includeAll,
    );
    return accumulator;
};

export const mapFileDataToFileDTO = (fileData: Record<string, any>, lang: string): AdditionalInfoFileDTO => {
    return {
        id: fileData?.data?.id,
        lang,
        category: fileData?.category,
    };
};

export const extractFilesDataFromCategoryFileDataArray = (
    categoryFileData: Array<any>,
    lang: string,
    accumulator: Array<AdditionalInfoFileDTO>,
): void => {
    for (let i = 0; i < categoryFileData.length; i++) {
        const fileData = categoryFileData[i];
        accumulator.push(mapFileDataToFileDTO(fileData, lang));
    }
};

/**
 * Функция итерируется по массиву внутри сабмишена
 */
export const extractFilesDataFromArray = (
    data: Array<any>,
    lang: string,
    accumulator: Array<AdditionalInfoFileDTO>,
    keysToMatch: Array<string>,
): void => {
    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (typeof element === 'object' && !Array.isArray(element)) {
            extractFilesDataFromObject(element, lang, accumulator, keysToMatch);
        }
    }
};
/**
 * Функция итерируется по объекту внутри сабмишена
 */
export const extractFilesDataFromObject = (
    data: Record<string, any>,
    lang: string,
    accumulator: Array<AdditionalInfoFileDTO>,
    keysToMatch: Array<string>,
): void => {
    const dataObjectKeys = Object.keys(data);
    for (let i = 0; i < dataObjectKeys.length; i++) {
        const key = dataObjectKeys[i];
        const valueByKey = data[key];
        if (keysToMatch.includes(key)) {
            extractFilesDataFromCategoryFileDataArray(valueByKey, lang, accumulator);
        } else if (Array.isArray(valueByKey)) {
            extractFilesDataFromArray(valueByKey, lang, accumulator, keysToMatch);
        } else if (typeof valueByKey === 'object') {
            extractFilesDataFromObject(valueByKey, lang, accumulator, keysToMatch);
        }
    }
};

/**
 * Функция извлекает из сабмишена данные файлов
 * @param submission Сабмишен
 * @param keysToMatch Массив строк, где каждая строка - "Property Name" компоненты, у которой component.type === 'categoryFile'
 * ("Property Name" в компоненте хранится как component.key)
 */
export const getFilesDataFromSubmission = (
    submission: Record<string, any>,
    keysToMatch: string[],
): Array<AdditionalInfoFileDTO> => {
    const accumulator: Array<AdditionalInfoFileDTO> = [];
    const locales = Object.keys(submission);
    for (let i = 0; i < locales.length; i++) {
        const locale = locales[i];
        if (!submission[locale].data) {
            continue;
        }
        extractFilesDataFromObject(submission[locale].data, locale, accumulator, keysToMatch);
    }
    return accumulator;
};
