import { css } from 'styled-components';

export default function (): string {
    return css`
        .formio-component-categoryFile {
            a[class*='file-list__block-file-link'] {
                position: relative;
                padding-left: 6px;
            }

            a[class*='file-list__block-file-link download-file']::after {
                content: '';
                border: 2px solid #e8e3e3;
                border-top: 2px solid #0968b5;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                position: absolute;
                top: 2px;
                left: -14px;
                animation: spin 1s linear infinite;
                -webkit-animation: spin 1s linear infinite;
            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                    -webkit-transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                    -webkit-transform: rotate(360deg);
                }
            }

            @-webkit-keyframes spin {
                0% {
                    transform: rotate(0deg);
                    -webkit-transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                    -webkit-transform: rotate(360deg);
                }
            }
        }
    `.join('');
}
